import detailPageStyle from 'component/detailPageStyle'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import React from 'react'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'

const DetailTableHeader = ({classes}) => {
  return (
    <GridContainer
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.tableHeaderGrid}
    >
      <GridItem container xs={12} sm={4} md={2} lg={2}>
        <Trans>Code</Trans>
      </GridItem>
      <GridItem container xs={12} sm={4} md={3}>
        <Trans>Description</Trans>
      </GridItem>
      <GridItem container xs={12} sm={4} md={1}>
        <Trans>Hourly wage</Trans>
      </GridItem>
      <GridItem container xs={12} sm={4} md={1} lg={1}>
        <Trans>Quantity</Trans>
      </GridItem>
      <GridItem container xs={12} sm={4} md={2}>
        <Trans>Tax</Trans>
      </GridItem>
      <GridItem container xs={12} sm={4} md={1} lg={1}>
        <Trans>Discount</Trans>
      </GridItem>
      <GridItem container xs={12} sm={4} md={2} lg={2}>
        <Trans>Price without tax</Trans>
      </GridItem>
    </GridContainer>
  )
}

export default withStyles(detailPageStyle)(DetailTableHeader)
